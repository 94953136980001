import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { reverse, sortBy } from 'lodash';
import { Helmet } from 'react-helmet';
import slugify from 'slugify';

import Layout from 'components/layout';
import ProjectFilter from 'containers/project-filter';
import ProjectList from 'containers/project-list';
import PageHeader from 'components/page-header';
import Button from 'components/blocks/button';

const Projects = ({ data, location }) => {
  const { allContentfulProject, contentfulProjectSnapshots } = data;
  const {
    seoDescription,
    seoImage,
    description,
    title,
    header,
  } = contentfulProjectSnapshots;

  const pageTitleFull = title
    ? `${title} | The Neighborhood Design Center`
    : 'The Neighborhood Design Center';
  const fullDescription =
    (seoDescription && seoDescription.seoDescription) ||
    (description && description) ||
    null;
  const imageUrl =
    (seoImage && `${seoImage.gatsbyImageData.images.fallback.src}`) || null;

  // const allJournalPosts = reverse(
  //   sortBy(allContentfulJournalPost.edges, [i => new Date(i.node.date)])
  // );

  return (
    <Layout location={location}>
      <Helmet>
        {pageTitleFull && <meta content={pageTitleFull} property="og:title" />}
        {pageTitleFull && <meta content={pageTitleFull} name="twitter:title" />}
        {pageTitleFull && <title>{pageTitleFull}</title>}
        {fullDescription && (
          <meta content={fullDescription} name="description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} property="og:description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} name="twitter:description" />
        )}
        {pageTitleFull && (
          <meta content={pageTitleFull} name="twitter:text:title" />
        )}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
        {imageUrl && <meta content={imageUrl} name="twitter:image" />}
        {imageUrl && <meta content="1024" name="twitter:image:width" />}
        {imageUrl && <meta content="512" name="twitter:image:height" />}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
      </Helmet>
      <div style={{ background: 'white' }}>
        <PageHeader
          align={!header.image && 'left'}
          size={!header.image && 'large'}
          pageColor="green"
          // pageLinks={filter(content.references, item => {
          //   return item.type && item.type === 'Scroll Anchor';
          // })}
          // pageLinks={filter(JSON.parse(content.raw).content, item => {
          //   return (
          //     item.data.target &&
          //     item.data.target.sys.contentType &&
          //     item.data.target.sys.contentType.sys.id ===
          //       'blockScrollAnchor' &&
          //     item.data.target.fields.type['en-US'] === 'Scroll Anchor'
          //   );
          // })}
          {...header}
        />
        <ProjectFilter items={allContentfulProject.edges} />
        <ProjectList items={allContentfulProject.edges} />
        <div className="text-center pb-16 pt-24 sm:pb-24 sm:pt-32">
          <Button
            text="Case Studies"
            link="/our-work"
            isInternal={true}
            isInline
          />
          <Button
            text="Our Services"
            link="/our-services"
            isInternal={true}
            isInline
          />
        </div>
      </div>
    </Layout>
  );
};

Projects.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default Projects;

export const query = graphql`
  query ProjectSnapshotsQuery {
    contentfulProjectSnapshots {
      id
      title
      seoDescription {
        seoDescription
      }
      seoImage {
        gatsbyImageData(width: 1024, height: 512)
      }
      description {
        description
        childMarkdownRemark {
          html
        }
      }
      header {
        title
        drawing
        cta {
          text
          link
          caption
          opensNewTab
        }
        image {
          gatsbyImageData(width: 1500, placeholder: BLURRED)
          #   fluid(maxWidth: 1500) {
          #     ...GatsbyContentfulFluid_withWebp
          #   }
          #   fixed(width: 1024, height: 512) {
          #     src
          #   }
        }
        cropCoordinatesX
        cropCoordinatesY
        description {
          raw
        }
        linksSection {
          id
          text
          link
          overlayColor
          icon {
            gatsbyImageData(width: 600)
          }
          backgroundImage {
            gatsbyImageData(width: 1500)
          }
          opensNewTab
        }
      }
    }

    allContentfulProject {
      edges {
        node {
          id
          published
          title
          slug
          category
          date
          images {
            id
            description
            gatsbyImageData(width: 1500, placeholder: BLURRED)
          }
          text {
            raw
          }
        }
      }
    }

    allContentfulJournalPost(
      filter: { published: { eq: true }, date: { ne: null } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          type
          date
          thumbnailImage {
            gatsbyImageData(width: 1500)
          }
          excerpt {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
